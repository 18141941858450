import { Fragment } from "react";

const ToolsAndBrushes = () => {
    return (
        <Fragment>
            <div>
                <h1>Tools and Brushes</h1>
            </div>
        </Fragment>
    );
}
export default ToolsAndBrushes;