import { useEffect, useState } from "react"; // Importing useState for state management and useEffect for side effects
import { useNavigate } from "react-router-dom"; // Importing useNavigate for programmatic navigation
import "./SignInPage.css"; // Importing the CSS file for styling the SignInPage component
import {
  fetchUserNameRequest,
  logInRequest,
} from "../../../connection/ServerRequest.js"; // Importing server request functions for login and fetching user information

/**
 * SignInPage component
 *
 * This component handles the user login functionality. It provides a form for users to enter their
 * username and password, and handles form submission to authenticate the user.
 *
 * @component
 * @returns {JSX.Element} The JSX for the SignInPage component.
 */
const SignInPage = () => {
  // State variables for managing username, password, and error messages
  const [username, setUsername] = useState(""); // State for storing the entered username
  const [password, setPassword] = useState(""); // State for storing the entered password
  const [error, setError] = useState(null); // State for storing any error messages during login
  const navigate = useNavigate(); // Hook for navigating programmatically to different routes

  // Handler function to update the username state when the user types in the username field
  const onUsernameHandler = (event) => {
    setUsername(event.currentTarget.value);
  };

  // Handler function to update the password state when the user types in the password field
  const onPasswordHandler = (event) => {
    setPassword(event.currentTarget.value);
  };

  // Handler function for form submission, sends a login request to the server
  const onSubmitHandler = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    const body = { username, password }; // Creates a body object containing the username and password
    const response = await logInRequest(body); // Sends the login request to the server

    if (response.success) {
      window.history.back();
    } else {
      setError(response.message); // Displays an error message if the login fails
    }
  };

  // Handler function for the sign-up link click, navigates to the registration page
  const handleSignUpClick = (event) => {
    event.preventDefault(); // Prevents the default link behavior
    navigate("/signIn/register"); // Navigates to the sign-up page
  };

  // useEffect to validate the token and check if the user is already logged in
  useEffect(() => {
    async function validateToken() {
      try {
        const response = await fetchUserNameRequest(); // Sends a request to fetch the username
        if (response.success) {
          navigate("/myprofile"); // Navigates to the profile page if the user is already logged in
        }
      } catch (error) {
        console.error("Error validating token:", error); // Logs any errors during token validation
      }
    }
    validateToken(); // Calls the validateToken function when the component mounts
  }, [navigate]); // The effect depends on the navigate function

  return (
    <div className="sign-form-box">
      <form className="sign-form" onSubmit={onSubmitHandler}>
        <p id="heading" className="sign-title">
          Log in to your Account!
        </p>
        {/* Displays an error message if there is an error during login */}
        {error && <p className="sign-error">{error}</p>}
        <div className="sign-field sign-form-container">
          {/* Username input field with an icon */}
          <svg
            className="sign-input-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
          </svg>
          <input
            autoComplete="off"
            placeholder="Username"
            value={username}
            className="sign-input"
            type="text"
            onChange={onUsernameHandler} // Updates username state on input change
          />
        </div>
        <div className="sign-field sign-form-container">
          {/* Password input field with an icon */}
          <svg
            className="sign-input-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"></path>
          </svg>
          <input
            placeholder="Password"
            value={password}
            className="sign-input"
            type="password"
            onChange={onPasswordHandler} // Updates password state on input change
          />
        </div>
        <div className="sign-btn">
          <button className="button1" type="submit">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
        </div>

        <div className="sign-text">
          <p>
            Don't have an account yet? Go{" "}
            <a
              href="/signUp"
              className="signup-ref"
              onClick={handleSignUpClick} // Handles sign-up link click to navigate to the registration page
            >
              Sign Up
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default SignInPage; // Exporting the SignInPage component as the default export
