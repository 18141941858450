import Logo from "./logo/Logo";
import SearchBar from "./searchBar/SearchBar";
import ProfileButton from "./profile/profileButton/ProfileButton";
import { Link } from "react-router-dom";
import "./Header.css"; // Import the CSS file for the Header component
import NavBar from "./navBar/NavBar";

/**
 * Header component
 *
 * This component renders a Header component.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Header" component.
 */
const Header = () => {
  return (
    <header className="header">
      <div className="top-section">
        <Logo />
        <SearchBar />
        <Link to="/myprofile" className="profile-link">
          <ProfileButton />
        </Link>
      </div>
      <NavBar />
    </header>
  );
};

export default Header;
