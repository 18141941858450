import { Fragment, useContext, useEffect, useState } from "react";
import UserSkincareInputDropDown from "./userSkincareInputDropDown/UserSkincareInputDropDown";
import { DropDownData } from "./dropDownData/DropDownData";
import "./UserSkincareInput.css";
import ButtonContainer from "../../backSubmitButtonContainer/backSubmitButtonContainer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { fetchUserSkintypeInfoRequest } from "../../../connection/ServerRequest";
import {
  UpdateFunctionContext,
  UserInputContext,
} from "../../../context/ContextProvider";

/**
 * UserSkincareInput component
 *
 * This component renders the user skincare input form when the user clicks "Find My Skincare Products" button.
 * It includes various dropdowns for different skincare attributes and a submit button.
 *
 * @component
 * @returns {JSX.Element} A React component that displays the user skincare input form.
 */
const UserSkincareInput = () => {
  const { updateUserInput } = useContext(UpdateFunctionContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useState(null);
  const {
    skinTone,
    skinType,
    pore,
    sensitive,
    blemish,
    aging,
    uneven,
    wrinkle,
    darkCircle,
    acnePimple,
  } = DropDownData;
  const userInputContext = useContext(UserInputContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserSkintypeInfoRequest();
        if (response.success) {
          const {
            level_acne_Pimple,
            level_aging,
            level_blemish,
            level_dark_Circle,
            level_uneven,
            level_wrinkle,
            pore,
            sensitivity,
            skin_Tone,
            skin_Type,
          } = response.skinData;

          const skinData = {
            acnePimple: level_acne_Pimple,
            aging: level_aging,
            blemish: level_blemish,
            darkCircle: level_dark_Circle,
            uneven: level_uneven,
            wrinkle: level_wrinkle,
            pore: pore,
            sensitive: sensitivity,
            skinTone: skin_Tone,
            skinType: skin_Type,
          };

          console.log(skinData);
          updateUserInput(skinData); // Pass the entire skinData object
          console.log(userInputContext);
        } else {
          console.error("Failed to fetch product results:", response.message);
        }
      } catch (error) {
        console.error("Error fetching product results", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Helper function to render a dropdown with a label
  const renderDropdown = (label, data, inputField) => {
    return (
      <div className="dropdown-container">
        <span className="label">{label}</span>
        <span className="dropdown-span">
          <UserSkincareInputDropDown
            data={data}
            inputField={inputField}
            onSelectionChange={handleSelectionChange}
          />
        </span>
      </div>
    );
  };

  const handleSelectionChange = () => {
    if (currentSlide < inputArray.length - 1) {
      carouselRef.current.next();
    }
  };

  const inputArray = [
    { label: "Your Skin Tone", data: skinTone, inputField: "skinTone" },
    { label: "Your Skin Type", data: skinType, inputField: "skinType" },
    { label: "Concerned with Pores?", data: pore, inputField: "pore" },
    {
      label: "Concerned with Skin Sensitiveness?",
      data: sensitive,
      inputField: "sensitive",
    },
    { label: "Concerned with Blemish?", data: blemish, inputField: "blemish" },
    { label: "Concerned with Aging Skin?", data: aging, inputField: "aging" },
    {
      label: "Concerned with Skin Unevenness?",
      data: uneven,
      inputField: "uneven",
    },
    { label: "Concerned with Wrinkles?", data: wrinkle, inputField: "wrinkle" },
    {
      label: "Concerned with Dark Circles?",
      data: darkCircle,
      inputField: "darkCircle",
    },
    {
      label: "Concerned with Acnes or Pimples?",
      data: acnePimple,
      inputField: "acnePimple",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // Optional, defaults to 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // Optional, defaults to 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // Optional, defaults to 1
    },
  };

  return (
    <Fragment>
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        afterChange={(previousSlide, { currentSlide }) => {
          setCurrentSlide(currentSlide);
        }}
      >
        {inputArray.map((input, index) => {
          return (
            <div key={index} className="input-card-container">
              <div className="input-card">
                {renderDropdown(input.label, input.data, input.inputField)}
              </div>
            </div>
          );
        })}
      </Carousel>
      <ButtonContainer />
    </Fragment>
  );
};
export default UserSkincareInput;
