import { Fragment } from "react"; // Importing Fragment from React to group multiple elements
import { Link } from "react-router-dom"; // Importing Link from react-router-dom for navigation between routes
import SocialMedia from "./socialMedia/SocialMedia"; // Importing the SocialMedia component to display social media links/icons
import "./Footer.css"; // Importing the CSS file for styling the Footer component

/**
 * Footer component
 *
 * This component renders the "Footer" section of the application. It includes navigation links,
 * social media icons, and a copyright notice.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Footer" section.
 */
const Footer = () => {
  return (
    <Fragment>
      <div className="footer">
        <div>
          {/* Navigation links to other parts of the application */}
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/faq">FAQ</Link>
        </div>
        <div>
          {/* Renders the SocialMedia component */}
          <SocialMedia />
        </div>
        <div>{/* Displays a copyright notice */}© 2024 dasoda</div>
      </div>
    </Fragment>
  );
};

export default Footer; // Exporting the Footer component as the default export
