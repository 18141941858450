import { Outlet } from "react-router-dom";
import Header from "../header/Header.jsx";
import Footer from "../footer/Footer.jsx";
import "./Layout.css"

/**
 * Layout component
 *
 * This component renders a Header, Footer, and other components wrapped between Layout tags.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Layout" component.
 */
const Layout = () => {
  return (
    <div>
      <Header />
      <main className="content">
        <Outlet /> {/* This is where nested route components will be rendered */}
      </main>
      <Footer />
    </div>
  );
};
export default Layout;
