import { Routes, Route } from "react-router-dom";
import Layout from "../component/layout/Layout";
import WelcomePage from "../component/mainPage/welcomePage/WelcomePage";
import About from "../component/footer/about/About";
import Contact from "../component/footer/contact/Contact";
import FAQ from "../component/footer/faq/FAQ";
import UserSkincareInput from "../component/mainPage/userSkincareInput/UserSkincareInput";
import UserSkincareProductRecommendation from "../component/mainPage/userSkincareProductRecommendation/UserSkincareProductRecommendation";
import SignInPage from "../component/registering/signInPage/SignInPage";
import SignUp from "../component/registering/signUpPage/SignUpPage";
import SearchResultsPage from "../component/mainPage/searchPage/SearchResultsPage";
import ProductsInfo from "../component/mainPage/searchPage/productsInfo/ProductsInfo";
import PrivateProfilePage from "../component/header/profile/privateProfilePage/PrivateProfilePage";
import PublicProfilePage from "../component/header/profile/publicProfilePage/PublicProfilePage";
import ForYouRecommendation from "../component/mainPage/forYouRecommendation/ForYouRecommendation";
import SearchProducts from "../component/mainPage/searchProducts/SearchProducts";
import Categories from "../component/mainPage/categories/Categories";
import Skincare from "../component/mainPage/categories/skincare/Skincare";
import BathAndBody from "../component/mainPage/categories/bathAndBody/BathAndBody";
import HairFragrance from "../component/mainPage/categories/hairFragrance/HairFragrance";
import Makeup from "../component/mainPage/categories/makeup/Makeup";
import ToolsAndBrushes from "../component/mainPage/categories/toolsAndBrushes/ToolsAndBrushes";

/**
 * Routing component
 *
 * This component sets up the application's routing using react-router-dom.
 *
 * @component
 * @returns {JSX.Element} A React component that sets up the application's routing.
 */
const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<WelcomePage />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="myprofile" element={<PrivateProfilePage />} />
        <Route path="/profile/:userId" element={<PublicProfilePage />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="userSkincareInput" element={<UserSkincareInput />} />
        <Route path="signIn" element={<SignInPage />} />
        <Route path="/signIn/register" element={<SignUp />} />
        <Route
          path="userSkincareProductRecommendation"
          element={<UserSkincareProductRecommendation />}
        />
        <Route path="/search/:keyword" element={<SearchResultsPage />} />
        <Route path="/product/:productId" element={<ProductsInfo />} />
        <Route path="/forYouRecommendation" element={<ForYouRecommendation />} />
        <Route path="/searchProducts" element={<SearchProducts />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/skincare" element={<Skincare />} />
        <Route path="/categories/bathAndBody" element={<BathAndBody />} />
        <Route path="/categories/hairFragrance" element={<HairFragrance />} />
        <Route path="/categories/makeup" element={<Makeup />} />
        <Route path="/categories/toolsAndBrushes" element={<ToolsAndBrushes />} />
      </Route>
    </Routes>
  );
};
export default Routing;
