import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./FindSkincareProductButton.css";

/**
 * FindSkinCareProductButton component
 *
 * This component renders a button that navigates to the user skincare input page when clicked.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a button to find skincare products.
 */
const FindSkinCareProductButton = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes

  const handleClick = () => {
    navigate("/userSkincareInput"); // Adjust the path as necessary
  };
  return (
    <Fragment>
      <div class="card">
        <div class="card-header">
          <span class="title">Personalize Skincare Products</span>
          {/* <span class="price">Free</span> */}
        </div>
        {/* <p class="desc">Discover products tailored just for you!</p> */}
        <ul class="lists">
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>AI-powered skincare recommendations</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Regularly updated product pool</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>All products available at Sephora</span>
          </li>
        </ul>
        <button
          type="button"
          class="find-your-skincare-button"
          onClick={handleClick}
        >
          Find Your Skincare!
        </button>
      </div>
    </Fragment>
  );
};
export default FindSkinCareProductButton;
