import {  useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";

/**
 * SearchBar component
 *
 * This component renders the "Search Bar" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Search Bar" section.
 */
const SearchBar = () => {
  const navigate = useNavigate();
  // state to store the input value of the search bar
  const [searchInput, setSearchInput] = useState("");

  /**
   * Handles the typing event into the search bar.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event triggered by typing into the search bar.
   */
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  // deals with the search happens
  const handleSearch = () => {
    if (searchInput.trim()) {
      navigate(`/search/${encodeURIComponent(searchInput)}`);
    } else {
      navigate("/");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="container">
      <div className="search-container">
        <input
          className="input"
          type="text"
          placeholder="Search Products"
          onChange={handleChange}
          value={searchInput}
          onKeyDown={handleKeyDown}
        />
        <svg
          viewBox="0 0 24 24"
          className="search__icon"
          onClick={handleSearch}
        >
          <g>
            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default SearchBar;
