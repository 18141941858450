import { Fragment, useEffect, useState } from "react";
import { FetchAllSkincareProductsRequest } from "../../../../connection/ServerRequest";

const Skincare = () => {
    
    const [skincareProducts, setSkincareProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await FetchAllSkincareProductsRequest();
                console.log(response);
                setSkincareProducts(response.data);
               
            } catch (error) {
                console.error("Error fetching skincare products:", error);
            }
        };

        fetchData();

    }, [])

    return (
        <Fragment>
            <div>
                <h1>{skincareProducts.map((product) => {
                    return (
                        <div>
                            <h3>{product.ProductID}</h3>
                        </div>
                    )
                })}</h1>
            </div>
        </Fragment>
    );
}
export default Skincare;