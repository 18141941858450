import React, { Fragment } from "react"; // Importing React and Fragment from React to structure the component
import "./Modal.css"; // Importing the CSS file for styling the Modal component

/**
 * Modal component
 *
 * This component renders a modal dialog with a message and an "onClose" handler. The modal is displayed
 * on top of the current page content, and can be closed by clicking the overlay or the close button.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.message - The message to display inside the modal
 * @param {function} props.onClose - The function to call when the modal is closed
 * @returns {JSX.Element} A React component that displays a modal dialog.
 */
const Modal = ({ message, onClose }) => {
  return (
    <Fragment>
      {/* Overlay that covers the screen and closes the modal when clicked */}
      <div className="Modal-Overlay" onClick={onClose}>
        {/* Modal content container, click event is stopped from propagating to prevent closing */}
        <div className="Modal-Form" onClick={(e) => e.stopPropagation()}>
          {/* Close button to close the modal */}
          <button className="exitBtn" onClick={onClose}>
            &times;
          </button>
          {/* Heading of the modal */}
          <h2 className="Modal-Heading">Not Successful</h2>
          {/* Description text or message passed as a prop */}
          <p className="Modal-Desc">{message}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Modal; // Exporting the Modal component as the default export
