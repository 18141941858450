import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ProductInfoContext,
  ReviewListContext,
  UpdateFunctionContext,
} from "../../../../context/ContextProvider";
import {
  fetchProductIndividualRatingRequest,
  fetchProductInfoDetailRequest,
  fetchUserNameRequest,
  fetchUserSkintypeInfoRequest,
  getProductReviewsRequest,
  storeUserReviewRequest,
} from "../../../../connection/ServerRequest";
import "./ProductsInfo.css";
import BackButton from "../../../backButton/BackButton";
import RatingDisplay from "../ratingDisplay/RatingDisplay";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import ReviewInsertBox from "../../reviews/ReviewInsertBox";
import Pagination from "react-js-pagination";
import ReviewList from "../../../reviewList/ReviewList";

const ProductsInfo = () => {
  const { updateProductInfo, updateReviewList } = useContext(UpdateFunctionContext);
  const productInfo = useContext(ProductInfoContext);
  const reviewList = useContext(ReviewListContext);
  const { productId } = useParams();
  const [rating, setRating] = useState(null);
  const [userId, setUserId] = useState('');
  const [isReviewBoxVisible, setIsReviewBoxVisible] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const navigate = useNavigate();

  // Fetch the product details into card
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchProductInfoDetailRequest(productId);

        if (response.success) {
          updateProductInfo(response.data);
        } else {
          console.error("Failed to fetch product results:", response.message);
        }
      } catch (error) {
        console.error("Error fetching product results: ", error);
      }
    };
  
    if (productId) {
      fetchData();
    }
  }, [productId, updateProductInfo]);

  // Fetch every reviews of this product
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const result = await getProductReviewsRequest(productId);
        const userResult =  await fetchUserNameRequest();
        if (userResult.success){
          setUserId(userResult.userId)
        }
        if (result.success) {
          updateReviewList(result.data);
        } else {
          console.error("Failed to fetch reviews of this product:", result.message);
        }
      } catch (error) {
        console.error("Error fetching reviews of this product:", error);
      }
    };

    if (productId) {
      fetchReviews();
    }
  }, [productId, updateReviewList]);

  // Fetch expected rating if user is logged in
  useEffect(() => {
    const fetchRating = async () => {
      try {
        const userResult = await fetchUserSkintypeInfoRequest();
        if (userResult.success) {
          const combinationId = userResult.skinData.combination_Id;
          if (productId && combinationId) {
            const productfetchRating = await fetchProductIndividualRatingRequest(
              combinationId,
              productId
            );
            setRating(productfetchRating.rating);
          }
        }
      } catch (error) {
        console.error("Error fetching product results", error);
      }
    };
    fetchRating();

  }, [productId]);

  const toggleReviewBox = () => {
    setIsReviewBoxVisible((prev) => !prev);
  };

  const handleWriteReview = async () => {
    const result = await fetchUserNameRequest();
    if (result.success) {
      setUserId(result.userId);
      console.log(result)
      toggleReviewBox();
        

    } else {
      navigate("/SignIn")
      console.log("You must be logged in to write a review.");
    }
  };

  const handleSubmitReview = async (review) => {
    const { productId, finalRating, reviewText } = review; 
  
    console.log(productId, rating, reviewText)
    const result = await storeUserReviewRequest(productId, finalRating, reviewText);
    
    console.log(result)
    if (result.success) {
      setIsReviewBoxVisible(false);
      console.log("Review submitted:", review);
      // Fetch updated reviews after submitting a new review
      const updatedReviews = await getProductReviewsRequest(productId);
      if (updatedReviews.success) {
        setReviews(updatedReviews.data);
        updateReviewList(updatedReviews.data);
      }
    } else {
      console.error("Failed to submit review:", result.message);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get the current reviews based on the current page
  const indexOfLastReview = currentPage * itemsPerPage;
  const indexOfFirstReview = indexOfLastReview - itemsPerPage;
  const currentReviews = reviewList.slice(indexOfFirstReview, indexOfLastReview);

  return (
    <div className= "productinfo-page">
      <div className="productinfo-card">
        <div className="productinfo-image">
          <img src={productInfo.imageTags} alt="productimage" />
        </div>
        <h1 className="productinfo-name">{productInfo.name}</h1>
        <p className="productinfo-id">Product ID: {productInfo.productId}</p>
        <p className="productinfo-type">Type: {productInfo.type}</p>
        <p className="productinfo-brand">Brand: {productInfo.brandName}</p>
        <p className="productinfo-love">❤️ {productInfo.loveCount} Likes</p>
        <RatingDisplay rating={rating} description = "Expected Rating"/> {/* Display rating */}
      </div>

      {/* Review Section */}
      <div className="review-section">
        <div className="review-header">
          <h2>Check how others say about this product!</h2>
          <button className="write-review-button" onClick={handleWriteReview}>
            <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
        {isReviewBoxVisible && (
          <ReviewInsertBox
            productId={productInfo.productId}
            productName={productInfo.name}
            brandName={productInfo.brandName}
            uid= {userId}
            onSubmitReview={handleSubmitReview}
            onCancel={toggleReviewBox}
          />
        )}
        <ReviewList reviews = {currentReviews} userId = {userId}></ReviewList>

        {/* Pagination Component */}
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={reviews.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>

      <div>
        <BackButton />
      </div>
    </div>
  );
};

export default ProductsInfo;
