import React, { useState } from 'react';
import './EditAbout.css'; // Import the CSS for styling
/**
 * This is textEdit component
 * 
 * @param {initialAbout , onSave, onCancel} 
 * @returns {JSX.Element}
 */
const EditAbout = ({ initialAbout, onSave, onCancel }) => {
  const [aboutText, setAboutText] = useState(initialAbout);

  const handleSave = () => {
    onSave(aboutText);
    
  };

  const handleCancel = () => {
    onCancel(); // Trigger the cancel action
  };

  return (
    <div className="edit-about">
      <textarea
        value={aboutText}
        onChange={(e) => setAboutText(e.target.value)}
        rows="4"
        cols="50"
      />
      <div className="edit-about-buttons">
        <button onClick={handleSave} className="save-button">Save</button>
        <button onClick={handleCancel} className="cancel-button">Cancel</button>
      </div>
    </div>
  );
};

export default EditAbout;
