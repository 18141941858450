import { Fragment } from 'react';
import './NavBar.css';
import ForYouButton from './forYouButton/ForYouButton';
import SearchProductsButton from './searchProductsButton/SearchProductsButton';
import CategoriesButton from './categoriesButton/CategoriesButton';

const NavBar = () => {
    return (
        <Fragment>
            <div className="navbar">
                <CategoriesButton />
                <ForYouButton />
                <SearchProductsButton />
            </div>
        </Fragment>
    )
}
export default NavBar;