import { Fragment } from "react"
import { useNavigate } from "react-router-dom";

const SearchProductsButton = () => {

    const navigate = useNavigate();

    const handleSearchProductsButtonClick = () => {
        navigate("/searchProducts");
    }

    return (
        <Fragment>
            <div>
                <button onClick={handleSearchProductsButtonClick}>Search Products</button> 
            </div>      
        </Fragment>
    )
}
export default SearchProductsButton;