import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductList.css";

/**
 * ProductList component
 *
 * This component renders a list of products. It takes an array of product objects as a prop and
 * maps over them to display each product's ID.
 *
 * @component
 * @param {Object[]} props.products - The array of product objects to display
 * @param {string} props.products[].product_id - The unique identifier of each product
 * @returns {JSX.Element} A React component that displays a list of products.
 */
const SearchProductList = ({ products }) => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleProductClick = (productId) => {
    navigate(`/product/${encodeURIComponent(productId)}`); // Navigate to /product/productId
  };
  return (
    <Fragment>
      {products.map((product) => (
        <div
          className="product-container"
          onClick={() => handleProductClick(product.ProductID)}
        >
          <div className="product-image">
            <img src={product.ImageTags} alt="product" />
          </div>
          <div className="product-details">
            <div className="product-header">
              <div className="product-name">{product.ProductName}</div>
              <div className="product-id"> {product.ProductID}</div>
            </div>
            <div className="product-subdetails">
              <div className="product-brand">{product.BrandName}</div>
              <div className="product-type">Type: {product.Type}</div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default SearchProductList; // Exporting the ProductList component as the default export
