import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./UserList.css";


const SearchUserList = ({ users }) => {
    const navigate = useNavigate(); // Initialize the useNavigate hook
  
    const handleProductClick = (userId) => {
      navigate(`/profile/${encodeURIComponent(userId)}`); // Navigate to /product/productId
    };
    return (
      <Fragment>
        {users.map((user) => (
          <div
            className="userSearch-container"
            onClick={() => handleProductClick(user.UID)}
          >
            <div className="userSearch-image">
              <img src={user.ImageTags} alt="user" />
            </div>
            <div className="user-details">
              <div className="product-header">
                <div className="user-name">{user.Username}</div>
                <div className="user-id"> @{user.UID}</div>
            </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };
  
  export default SearchUserList; // Exporting the ProductList component as the default export