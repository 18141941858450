import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  UpdateFunctionContext,
  UserProfileInfoContext,
} from "../../../../context/ContextProvider";
import { fetchUserProfileInfoRequest } from "../../../../connection/ServerRequest";
import "./PublicProfilePage.css";
import { IoMdPerson } from "react-icons/io";

/**
 * ProfilePage component
 *
 * This component displays the user's profile information and provides a logout button. It uses the
 *
 *
 * @component
 * @returns {JSX.Element} A React component that displays the user's profile page with a logout option.
 */

const PublicProfilePage = () => {
  const { updateUserProfileInfo } = useContext(UpdateFunctionContext);
  const userProfileInfo = useContext(UserProfileInfoContext);
  const { userId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserProfileInfoRequest(userId);

        if (response.success) {
          const profileData = response.body; // Extract the actual user data from the response body

          // Check if the current data is different from the state before updating
          if (
            profileData.UID !== userProfileInfo.userId ||
            profileData.Username !== userProfileInfo.userName ||
            profileData.Gender !== userProfileInfo.gender ||
            profileData.About !== userProfileInfo.about
          ) {
            updateUserProfileInfo(profileData); // Only update if data has changed
          }
        } else {
          console.error(
            "Failed to fetch user profile results:",
            response.message
          );
        }
      } catch (error) {
        console.error("Error fetching userProfile results", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, updateUserProfileInfo, userProfileInfo]); // Include userProfileInfo to compare current state

  return (
    <div className="user-profile-container">
      <div className="user-profile-header">
        <IoMdPerson size={100} className="user-profile-picture" />
        <div className="user-profile-info">
          <h1 className="user-profile-name">{userProfileInfo.userName}</h1>
          <p className="user-profile-username">@{userProfileInfo.userId}</p>
          <p className="user-profile-gender">
            Gender: {userProfileInfo.gender}
          </p>
        </div>
      </div>
      <div className="user-profile-content">
        <h2 className="user-profile-section-title">About</h2>
        <p className="about-text">{userProfileInfo.about}</p>{" "}
        {/* Displaying about text */}
        <h2 className="user-profile-section-title">Posts</h2>
        {/* Add posts or other content here */}
      </div>
    </div>
  );
};
export default PublicProfilePage;
