import { Fragment } from "react";

const HairFragrance = () => {
  return (
    <Fragment>
      <div>
        <h1>Hair Fragrance</h1>
      </div>
    </Fragment>
  );
}
export default HairFragrance;