import React from "react"; // Importing React to create a functional component
import "./backSubmitButtonContainer.css"; // Importing the CSS file for styling the ButtonContainer component
import BackButton from "../backButton/BackButton"; // Importing the BackButton component
import UserSkincareInputSubmitButton from "../mainPage/userSkincareInput/userSkincareInputSubmitButton/UserSkincareInputSubmitButton"; // Importing the UserSkincareInputSubmitButton component

/**
 * ButtonContainer component
 *
 * This component serves as a container for the BackButton and UserSkincareInputSubmitButton components.
 * It groups these buttons together and applies the necessary styling through the associated CSS class.
 *
 * @component
 * @returns {JSX.Element} A JSX element containing the BackButton and UserSkincareInputSubmitButton components.
 */
const backSubmitButtonContainer = () => {
  return (
    <div className="button-container">
      {/* Render the BackButton component */}
      <BackButton />
      {/* Render the UserSkincareInputSubmitButton component */}
      <UserSkincareInputSubmitButton />
    </div>
  );
};

export default backSubmitButtonContainer; // Exporting the ButtonContainer component as the default export
