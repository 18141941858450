import React, { createContext, useCallback, useMemo, useState } from "react";

// Initial structures for state and context
const UserInput = {
  skinTone: "",
  skinType: "",
  pore: "",
  sensitive: "",
  blemish: "",
  aging: "",
  uneven: "",
  wrinkle: "",
  darkCircle: "",
  acnePimple: "",
};

const UpdateFunction = {};

const Recommendation = [];
const ProductInfo = {
  productId: "",
  name: "",
  brandName: "",
  type: "",
  imageTags: "",
  loveCount: "",
  ingredients: [],
};
const SearchResults = [];
const UserSearchResults = [];
const UserProfileInfo = {
  userId: "",
  userName: "",
  gender: "",
  about: "",
};

const ReviewList = []


// Creating contexts
export const UserInputContext = createContext(UserInput);
export const UpdateFunctionContext = createContext(UpdateFunction);
export const RecommendationContext = createContext(Recommendation);
export const ProductInfoContext = createContext(ProductInfo);
export const UserSearchResultsContext = createContext(UserSearchResults);
export const SearchResultsContext = createContext(SearchResults);
export const UserProfileInfoContext = createContext(UserProfileInfo);
export const ReviewListContext =  createContext(ReviewList);

const ContextProvider = ({ children }) => {
  const [userInput, setUserInput] = useState(UserInput);
  const [recommendation, setRecommendation] = useState(Recommendation);
  const [productInfo, setProductInfo] = useState(ProductInfo);
  const [searchResults, setSearchResults] = useState(SearchResults);
  const [userProfileInfo, setUserProfileInfo] = useState(UserProfileInfo);
  const [userSearchResults, setUserSearchResults] = useState(UserSearchResults);
  const [reviewList, setReviewList] = useState(ReviewList);

  const updateUserInput = useCallback((inputField, value) => {
    // Check if inputField is an object, meaning multiple fields are being updated
    if (typeof inputField === "object" && inputField !== null) {
      setUserInput((prevUserInput) => ({
        ...prevUserInput,
        ...inputField,
      }));
    } else {
      // Update a single field
      setUserInput((prevUserInput) => ({
        ...prevUserInput,
        [inputField]: value,
      }));
    }
  }, []);

  const updateRecommendation = useCallback((array) => {
    setRecommendation(array);
  }, []);

  const updateSearchResults = useCallback((array) => {
    setSearchResults(array);
  }, []);
  const updateUserSearchResults = useCallback((array) => {
    setUserSearchResults(array);
  }, []);
  const updateProductInfo = useCallback((product) => {
    const updatedInfo = {
      productId: product.ProductID,
      name: product.ProductName,
      brandName: product.BrandName,
      type: product.Type,
      imageTags: product.ImageTags || "",
      loveCount: product.Love_Count,
      ingredients: "", // should be product.Ingredients
    };

    setProductInfo((prevProductInfo) => {
      if (
        prevProductInfo.productId !== updatedInfo.productId ||
        prevProductInfo.name !== updatedInfo.name ||
        prevProductInfo.brandName !== updatedInfo.brandName ||
        prevProductInfo.type !== updatedInfo.type ||
        prevProductInfo.imageTags !== updatedInfo.imageTags ||
        prevProductInfo.loveCount !== updatedInfo.loveCount
        // || prevProductInfo.ingredients !== updatedInfo.ingredients
      ) {
        return updatedInfo;
      } else {
        return prevProductInfo;
      }
    });
  }, []);

  const updateUserProfileInfo = useCallback((profile) => {
    const updatedProfileInfo = {
      userId: profile.UID,
      userName: profile.Username,
      gender: profile.Gender,
      about: profile.About,
    };

    setUserProfileInfo((prevUserProfileInfo) => {
      if (
        prevUserProfileInfo.userId !== updatedProfileInfo.userId ||
        prevUserProfileInfo.userName !== updatedProfileInfo.userName ||
        prevUserProfileInfo.gender !== updatedProfileInfo.gender ||
        prevUserProfileInfo.about !== updatedProfileInfo.about
      ) {
        return updatedProfileInfo;
      } else {
        return prevUserProfileInfo;
      }
    });
  }, []);

  const updateReviewList = useCallback((array) => {
    setReviewList(array);
  }, []);


  // Memoize the context values to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      updateUserInput,
      updateRecommendation,
      updateProductInfo,
      updateSearchResults,
      updateUserProfileInfo,
      updateUserSearchResults,
      updateReviewList,
    }),
    [
      updateUserInput,
      updateRecommendation,
      updateProductInfo,
      updateSearchResults,
      updateUserProfileInfo,
      updateUserSearchResults,
      updateReviewList,
    ]
  );

  return (
    <UserInputContext.Provider value={userInput}>
      <UpdateFunctionContext.Provider value={contextValue}>
        <RecommendationContext.Provider value={recommendation}>
          <ProductInfoContext.Provider value={productInfo}>
            <SearchResultsContext.Provider value={searchResults}>
              <UserSearchResultsContext.Provider value={userSearchResults}>
                <UserProfileInfoContext.Provider value={userProfileInfo}>
                  <ReviewListContext.Provider value= {reviewList}>
                    {children}
                  </ReviewListContext.Provider>
                </UserProfileInfoContext.Provider>
              </UserSearchResultsContext.Provider>
            </SearchResultsContext.Provider>
          </ProductInfoContext.Provider>
        </RecommendationContext.Provider>
      </UpdateFunctionContext.Provider>
    </UserInputContext.Provider>
  );
};

export default ContextProvider;
