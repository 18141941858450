import Axios from "axios"; // Importing Axios for making HTTP requests

// Base URLs for the server and API endpoints
const serverUrl = "http://3.99.76.1:3200"; // URL for the main server
const apiUrl = "http://3.99.76.1:3220"; // URL for the API server

// Function to send user input to the skincare recommendation model API

/**
 * Fetch detailed infomraion of the product from API server
 *
 * @async
 * @function fetchProductInfoDetailRequest
 * @param {string} productId - The user input data to send to the API.
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */
export async function fetchProductInfoDetailRequest(productId) {
  try {
    const encodedURI = encodeURIComponent(productId);
    const response = await Axios.get(`${apiUrl}/productInfo=${encodedURI}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}

export async function FetchAllSkincareProductsRequest() {
  try {
    const response = await Axios.get(`${apiUrl}/allSkincareProducts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all skincare products:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}

/**
 * Fetch products information from search results
 *
 * @async
 * @function SearchProductsRequest
 * @param {string} keyword - The user input data to send to the API.
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */

export async function searchProductsRequest(keyword) {
  try {
    const encodedURI = encodeURIComponent(keyword);
    const response = await Axios.get(`${apiUrl}/search=${encodedURI}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching product info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}


/**
 * Fetch user information from search results
 *
 * @async
 * @function SearchUserRequest
 * @param {string} keyword - The user input keyword to send to the server
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */

export async function searchUserRequest(keyword) {
  try {
    const encodedURI = encodeURIComponent(keyword);
    const response = await Axios.get(`${serverUrl}/searchUser=${encodedURI}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}


/**
 * Fetch expected rating of single product following to user 
 *
 * @async
 * @function fetchProductIndividualRatingRequest
 * @param {string} combination_Id  @param {string} product_Id - combination Id and product Id
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */
export async function fetchProductIndividualRatingRequest(
  combination_Id,
  product_Id
) {
  try {
    const encodedCombinationId = encodeURIComponent(combination_Id);
    const encodedProductId = encodeURIComponent(product_Id);
    const response = await Axios.get(
      `${apiUrl}/expect_ratings?combinationId=${encodedCombinationId}&productId=${encodedProductId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching individual rating product info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}
/**
 * Fetch users' skin information that has been saved in the DB
 *
 * @async
 * @function FetchUserSkintypeInfoRequest
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */
export async function fetchUserSkintypeInfoRequest() {
  const token = localStorage.getItem("token");
  if (!token) {
    return { success: false, message: "No Token" };
  }
  const requestBody = { token };
  const response = await Axios.post(
    serverUrl + "/protected/fetchUserSkintype",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    }
  );
  return { success: response.data.success, skinData: response.data.skinType };
}
/**
 * Fetch users' skin information that has been saved in the DB
 *
 * @async
 * @function storeUserSkintypeInfoRequest
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */

export async function storeUserSkintypeInfoRequest(userInputContext, comb_Id) {
  const token = localStorage.getItem("token");
  if (!token) {
    return { success: false, message: "No Token" };
  }
  // Ensure userInputContext is correctly formatted
  const requestBody = {
    combination_Id: comb_Id,
    skinTone: userInputContext.skinTone,
    skinType: userInputContext.skinType,
    pore: userInputContext.pore,
    sensitivity: userInputContext.sensitive, // Assuming sensitive matches the field in your context
    blemish: userInputContext.blemish,
    aging: userInputContext.aging,
    uneven: userInputContext.uneven,
    wrinkle: userInputContext.wrinkle,
    darkCircle: userInputContext.darkCircle,
    acnePimple: userInputContext.acnePimple,
    token: token,
  };

  try {
    const response = await Axios.post(
      serverUrl + "/protected/storeUserSkintype",
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: response.data.success }; // Successful request
  } catch (err) {
    console.error("Error storing user skintype info:", err);
    return {
      success: false,
      message: "Can't connect to server",
      error: err.message,
    };
  }
}

/**
 * Sends user input data to the skincare recommendation model API.
 *
 * @async
 * @function ModelApiRequest
 * @param {Object} userInputContext - The user input data to send to the API.
 * @returns {Promise<Object>} - An object containing the success status and response data or error message.
 */
export async function modelApiRequest(userInputContext) {
  try {
    const response = await Axios.post(
      apiUrl + "/skincareRecommendationModel",
      userInputContext,
      {
        headers: {
          "Content-Type": "application/json", // Specifies that the request body is in JSON format
        },
      }
    );

    if (response.status === 200) {
      return { success: true, data: response.data }; // Successful request
    } else {
      return {
        success: false,
        message: "Request failed with status code " + response.status, // Handles non-200 responses
      };
    }
  } catch (err) {
    return { success: false, message: "Can't connect to server" }; // Handles connection errors
  }
}

// Function to handle user login requests
/**
 * Sends a login request with the provided credentials.
 *
 * @async
 * @function LogInRequest
 * @param {Object} body - The login credentials (e.g., email and password).
 * @returns {Promise<Object>} - An object containing the success status and a message or token.
 */
export async function logInRequest(body) {
  try {
    const response = await Axios.post(serverUrl + "/login", body);

    if (response.data.success) {
      localStorage.setItem("token", response.data.token); // Stores the token in localStorage on successful login
      return { success: true };
    } else {
      return { success: false, message: response.data.message }; // Handles unsuccessful login attempts
    }
  } catch (err) {
    return { success: false, message: "Can't connect to server" }; // Handles connection errors
  }
}

// Function to handle user sign-up requests
/**
 * Sends a sign-up request with the provided user information.
 *
 * @async
 * @function SignUpRequest
 * @param {Object} body - The user information for signing up (e.g., name, email, password).
 * @returns {Promise<Object>} - An object containing the success status and a message.
 */
export async function signUpRequest(body) {
  try {
    const response = await Axios.post(serverUrl + "/signup", body);

    if (response.data.success) {
      return { success: true, message: "Successfully Signed Up!" };
    } else {
      return { success: false, message: response.data.message }; // Handles unsuccessful sign-up attempts
    }
  } catch (err) {
    return { success: false, message: "Can't connect to server" }; // Handles connection errors
  }
}

// Function to handle user logout requests
/**
 * Sends a logout request to the server.
 *
 * @async
 * @function LogOutRequest
 * @returns {Promise<Object>} - An object containing the success status and a message.
 */
export async function logOutRequest() {
  const token = localStorage.getItem("token"); // Retrieves the token from localStorage

  try {
    const requestBody = { token };
    const result = await Axios.post(serverUrl + "/protected/logout", requestBody, {
      headers: {
        Authorization: `Bearer ${token}`, // Includes the token in the Authorization header
      },
    });

    if (result.data.success) {
      localStorage.removeItem("token"); // Removes the token from localStorage on successful logout
      return { success: true, message: "Successfully logged out" };
    } else {
      return { success: false, message: "Failed to log out. Try again" }; // Handles unsuccessful logout attempts
    }
  } catch {
    return { success: false, message: "Can't connect to server" }; // Handles connection errors
  }
}

/**
 * Fetches User Information
 *
 * @async
 * @function FetchUserProfileInfoRequest
 * @returns {Promise<Object|boolean>} - An object containing user information and success status, or false if the token is missing.
 */
export async function fetchUserProfileInfoRequest(userId) {
  try {
    const encodedURI = encodeURIComponent(userId);
    const response = await Axios.get(`${serverUrl}/profile=${encodedURI}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}

/**
 * Store userProfile info request, use protected routes
 *
 * @async
 * @function StoreUserProfileAbout
 * @returns {Promise<Object|boolean>} - An object containing user information and success status, or false if the token is missing.
 */
export async function storeUserProfileAboutRequest(aboutText) {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    const reqbody = { aboutText: aboutText, token: token };
    const response = await Axios.post(
      serverUrl + "/protected/storeAbout",
      reqbody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error user profile info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}

// Function to fetch user information after login
/**
 * Fetches user information from the server after login.
 *
 * @async
 * @function FetchUserInfoRequest
 * @returns {Promise<Object|boolean>} - An object containing user information and success status, or false if the token is missing.
 */
export async function fetchUserInfoRequest() {
  const token = localStorage.getItem("token");
  if (!token) {
    return false; // Returns false if the token is not found
  }

  const requestBody = { token };
  const result = await Axios.post(
    serverUrl + "/protected/fetchuser",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Includes the token in the Authorization header
      },
    }
  );

  return { body: result.data.body, success: result.data.success }; // Returns user information and success status
}

// Function to fetch the username of the logged-in user
/**
 * Fetches the username of the logged-in user from the server.
 *
 * @async
 * @function FetchUserNameRequest
 * @returns {Promise<Object|boolean>} - An object containing the username and success status, or false if the token is missing.
 */
export async function fetchUserNameRequest() {
  const token = localStorage.getItem("token");
  if (!token) {
    return false; // Returns false if the token is not found
  }

  const requestBody = { token };
  const result = await Axios.post(
    serverUrl + "/protected/fetchusername",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Includes the token in the Authorization header
      },
    }
  );
   
  
  return result.data; // Returns the username and success status
}
// Review 


/**
 * Fetch every reeview of product corresponding to productId
 *
 * @async
 * @function getProductReviewsRequest
 * @returns {Promise<Object|boolean>} - An object containing the username and success status, or false if the token is missing.
 */

export async function getProductReviewsRequest(productId){
  try{
      const encodedURI = encodeURIComponent(productId);
      const response = await Axios.get(`${apiUrl}/productReviews=${encodedURI}`);
      return response.data;
  } catch (error) {
    console.error("Error fetching product reviews information: ", error);
    throw error; 
  }

}

/**
 * Store the Reviews of a product into db, this only can be done if user is logged in
 *
 * @async
 * @function storeUserReviewRequest
 * @returns {Promise<Object|boolean>} - An object containing the username and success status, or false if the token is missing.
 */

export async function storeUserReviewRequest(reviewProductId, reviewRating, reviewText){
  const token = localStorage.getItem("token");
  if (!token) {
    return false; // Returns false if the token is not found
  }

  const requestBody = { 
    productId : reviewProductId,
    rating : reviewRating,
    reviewText : reviewText,
    token : token
  };
  const result = await Axios.post(
    serverUrl + "/protected/storeReview",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Includes the token in the Authorization header
      },
    }
  );
  return result.data
}

/**
 * Check if the user's review exists in this product pid
 *
 * @async
 * @function checkUserReviewExitsRequest
 * @returns {Promise<Object|boolean>} -
 */

export async function checkUserReviewExitsRequest(uid,pid){
  try {
    const encodedUid = encodeURIComponent(uid);
    const encodedPid = encodeURIComponent(pid);
    const response = await Axios.get(`${serverUrl}/fetchUserReview?uid=${encodedUid}&pid=${encodedPid}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product info:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
}

/**
 * delete review of the users
 *
 * @async
 * @function deleteUserReviewRequest
 * @returns {Promise<Object|boolean>} -
 */
export async function deleteUserReviewRequest(pid){
  
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return {sucess:false}; // Returns false if the token is not found
    }
    const requestBody = { 
      pid: pid,
      token : token
    };
    const response = await Axios.post(`${serverUrl}/protected/deleteReview`, requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }
    );
    return response.data;
  } catch (error) {
  }

}