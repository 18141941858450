import React from "react";
import { IoMdPerson } from "react-icons/io";
import "./ProfileButton.css";

/**
 * ProfileButton component
 *
 * This component renders a profile button with an icon.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a profile button.
 */
const ProfileButton = () => {
  return (
    <button className="profile-button">
      <IoMdPerson size={24} />
    </button>
  );
};

export default ProfileButton;
