import FindSkinCareProductButton from "../findSkincareProductButton/FindSkincareProductButton";
import { Fragment } from "react";

const WelcomePage = () => {
  return (
    <Fragment>
      <FindSkinCareProductButton />
    </Fragment>
  );
};
export default WelcomePage;
