import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { SearchResultsContext, UpdateFunctionContext, UserSearchResultsContext } from "../../../context/ContextProvider";
import SearchProductList from "../../productList/SearchProductList";
import SearchUserList from "../../userList/SearchUserList";
import { searchProductsRequest, searchUserRequest } from "../../../connection/ServerRequest";
import Pagination from "react-js-pagination";
import '../Pagination.css';
import '../Tabs.css';



/**
 * SearchResultsPage component
 *
 * This component fetches and displays search results based on the keyword extracted from the URI,
 * and separates results into products and users categories with tabs.
 *
 * @component
 * @returns {JSX.Element} A React component that displays search results.
 */
const SearchResultsPage = () => {
  const { keyword } = useParams(); // Extract the keyword from the URL params
  const { updateSearchResults, updateUserSearchResults } = useContext(UpdateFunctionContext); // Access the update function from context
  const searchResultsContext = useContext(SearchResultsContext);
  const userSearchResultsContext = useContext(UserSearchResultsContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Customize the number of items per page
  const [selectedCategory, setSelectedCategory] = useState("products"); // Track the selected tab (products or users)

  useEffect(() => {
    // Define an async function to fetch data for both products and users
    const fetchData = async () => {
      try {
        // Fetch product results
        const productResponse = await searchProductsRequest(keyword);
        if (productResponse.success) {
          updateSearchResults(productResponse.data);
        } else {
          console.error("Failed to fetch product search results:", productResponse.message);
        }
      } catch (error) {
        console.error("Error fetching product search results:", error);
      }

      // Fetch user results
      try {
        const userResponse = await searchUserRequest(keyword);
        if (userResponse.success) {
          updateUserSearchResults(userResponse.data);
        } else {
          console.error("Failed to fetch user search results:", userResponse.message);
        }
      } catch (error) {
        console.error("Error fetching user search results:", error);
      }
    };

    if (keyword) {
      fetchData();
    }
  }, [keyword, updateSearchResults, updateUserSearchResults]); // Include updateUserSearchResults in dependencies

  // Determine the current items based on the selected category
  const currentItems = selectedCategory === "products"
    ? searchResultsContext.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : userSearchResultsContext.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to the first page when switching tabs
  };

  return (
    <div>
      <h2>Search Results for "{keyword}"</h2>
      
      {/* Category Tabs */}
      <div className="tabs">
        <button
          className={`tab ${selectedCategory === "products" ? "active" : ""}`}
          onClick={() => handleCategoryChange("products")}
        >
          Products
        </button>
        <button
          className={`tab ${selectedCategory === "users" ? "active" : ""}`}
          onClick={() => handleCategoryChange("users")}
        >
          Users
        </button>
      </div>

      {/* Render Results Based on Selected Tab */}
      {selectedCategory === "products" ? (
        <SearchProductList products={currentItems} />
      ) : (
        <SearchUserList users={currentItems} />
      )}

      {/* Pagination Component */}
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={selectedCategory === "products" ? searchResultsContext.length : userSearchResultsContext.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default SearchResultsPage;
