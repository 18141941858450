import React from 'react';
import ReactStars from 'react-stars'; // Ensure you have this installed for star ratings
import './RatingDisplay.css'

/**
 * RatingDisplay component
 *
 * This component displays expected ratings of each product
 *
 * @component
 * @returns {JSX.Element} A React component that displays product details.
 */
const RatingDisplay = ({ rating, isLoading , description }) => {
  const starRating = rating ? parseFloat(rating).toFixed(2) : null;

  return (
    <div className="rating-display">
      <div className="rating-text-container">
        {isLoading ? (
          'Loading...'
        ) : starRating !== null ? (
          <>
            <span className="rating-label">{description}</span>
            <span className="rating-value">{starRating}</span>
          </>
        ) : (
          <>
            <i className="fa fa-lock lock-icon" aria-hidden="true"></i>
            <span className="rating-value rating-unavailable">Not Available</span>
          </>
        )}
      </div>
      <div className="Stars">
        {starRating !== null ? (
          <ReactStars
            count={5}
            value={parseFloat(starRating)}
            size={24}
            color={'#ffd700'} // Gray for empty stars
            edit={false}
          />
        ) : (
          <ReactStars
            count={5}
            value={0}
            size={24}
            
            color={'#ffd700'}
            edit={false}
          />
        )}
      </div>
    </div>
  );
};

export default RatingDisplay;
