import { Fragment} from "react";
import './ReviewList.css';
import RatingDisplay from "../mainPage/searchPage/ratingDisplay/RatingDisplay";
import { FaTrashAlt } from 'react-icons/fa';
import { deleteUserReviewRequest } from "../../connection/ServerRequest";

/**
 * ReviewList component
 *
 * This component displays every reviews for each product
 *
 *
 * @component
 * @returns {JSX.Element} A React component that displays every review of fetched product
 */
const ReviewList = ({ reviews, userId }) => {
  const handleDelete = async (reviewProductID) => {
    const result = await deleteUserReviewRequest(reviewProductID);
    if (result.success){
      window.location.reload()
    }
  };


  return (
    <Fragment>
      {reviews.map((review) => (
        <div className="review-container" key={review.UID}>
          <div className="review-user-details">
            <div className="review-header">
              <div className="user-name">{review.user.Username}</div>
              <div className="user-id">@{review.UID}</div>
              {userId === review.UID && (
                <FaTrashAlt
                  className="delete-icon"
                  onClick={() => handleDelete(review.ProductID)}
                  title="Delete"
                />
              )}
            </div>
          </div>
          <div className="review-detail">
            <RatingDisplay rating={review.Rating}  />
            <div className="review-text">{review.ReviewText}</div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default ReviewList;
