import { Fragment } from "react";

/**
 * FAQ component
 *
 * This component renders the "FAQ" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "FAQ" section.
 */
const FAQ = () => {
  return (
    <Fragment>
      <div>FAQ</div>
    </Fragment>
  );
};
export default FAQ;
