import { Fragment } from "react";

/**
 * Contact component
 *
 * This componet renders the "Contact" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays an "Contact" section.
 *
 */
const Contact = () => {
  return (
    <Fragment>
      <div>Contact</div>
    </Fragment>
  );
};
export default Contact;
