import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './ReviewInsertBox.css';
import { checkUserReviewExitsRequest } from '../../../connection/ServerRequest';

const ReviewInsertBox = ({ productId, productName, brandName, uid, onSubmitReview, onCancel }) => {
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        if (productId && uid) {
          const response = await checkUserReviewExitsRequest(uid, productId);
          if (response.success) {
            setReviewText(response.data.ReviewText);
            setRating(response.data.Rating);
          }
        }
      } catch (error) {
        console.error("Error fetching user review: ", error);
      }
    };

    fetchReview(); // Call the fetch function here
  }, [uid, productId]); // Add uid and productId as dependencies

  // Handle star click to set the rating
  const handleStarClick = (index) => {
    setRating(index + 1); 
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const finalRating = rating > 0 ? rating : 1;
    const review = {
        productId,
        productName,
        brandName,
        reviewText,
        finalRating,
      };
    console.log(review);
    onSubmitReview(review);

    // Clear the form after submission
    setReviewText('');
    setRating(0);
  };

  return (
    <div className="review-input-form">
      <h1>Write a Review!</h1>
      <form onSubmit={handleSubmit}>
        <div className="rating-input">
          <p><strong>Rate this item! :</strong></p>
          <div className="stars">
            {[...Array(5)].map((star, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className={index < rating ? 'star filled' : 'star'}
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
        </div>

        <div className="review-text-input">
          <label htmlFor="reviewText"><strong></strong></label>
          <textarea
            id="reviewText"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            placeholder="Write your review here..."
            rows="4"
            required
          ></textarea>
        </div>

        <div className="review-buttons">
          <button type="submit" className="submit-review-button">
            Submit 
          </button>
          <button type="button" className="cancel-review-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReviewInsertBox;
