import { Fragment } from "react";

/**
 * About component
 *
 * This component renders the "About" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays an "About" section.
 */
const About = () => {
  return (
    <Fragment>
      <div>About</div>
    </Fragment>
  );
};
export default About;
