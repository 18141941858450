import React, { useEffect, useState } from "react";
import {
  fetchUserInfoRequest,
  logOutRequest,
  storeUserProfileAboutRequest,
} from "../../../../connection/ServerRequest.js";
import { useNavigate } from "react-router-dom";
import { IoMdPerson, IoMdCreate } from "react-icons/io";
import "./PrivateProfilePage.css";
import EditAbout from "../EditButton/EditAbout.jsx";
/**
 *  This returns the private profile page for each user
 * @returns {JSX.ELement}
 */
const PrivateProfilePage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    Username: "",
    UserId: "",
    Email: "",
    Birthdate: "",
    Gender: "",
    PhoneNumber: "",
    About: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  // format phone number to  (xxx)- xxx - xxxx
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  // Deals the action for log out request by user
  const handleLogout = async () => {
    const response = await logOutRequest();
    if (response.success) {
      navigate("/signIn");
      window.location.reload();
    }
  };

  const handleSaveAbout = async (newAbout) => {
    setUserInfo((prev) => ({
      ...prev,
      About: newAbout,
    }));

    const result = await storeUserProfileAboutRequest(newAbout);

    if (result.success) {
      setIsEditing(false);
    }
  };
  // When user sets to cancel
  const handleCancelEdit = () => {
    setIsEditing(false);
  };
  // Fetch user Data from server
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchUserInfoRequest();
        if (!response.success) {
          navigate("/SignIn");
        }
        // setting user information to browser
        setUserInfo({
          Username: response.body.UserName,
          UserId: response.body.UID,
          Email: response.body.Email,
          Birthdate: response.body.BirthDate,
          Gender: response.body.Gender,
          PhoneNumber: formatPhoneNumber(response.body.PhoneNumber),
          About: response.body.About,
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    }
    fetchData();
  }, [navigate]);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <IoMdPerson size={100} />
        <div className="profile-info">
          <h1>{userInfo.Username}</h1>
          <p>@{userInfo.UserId}</p>
        </div>
      </div>
      <div className="profile-content">
        <section className="details">
          <h2>Personal Information</h2>
          <div className="detail-item">
            <span className="detail-label">Email:</span>
            <span className="detail-value">{userInfo.Email}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Mobile:</span>
            <span className="detail-value">{userInfo.PhoneNumber}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Birthdate:</span>
            <span className="detail-value">
              {userInfo.Birthdate.split("T")[0]}
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Gender:</span>
            <span className="detail-value">{userInfo.Gender}</span>
          </div>
        </section>
        <section className="about">
          <div className="about-header">
            <h2>
              <span>About</span>
              {!isEditing && (
                <IoMdCreate
                  size={24}
                  className="edit-icon"
                  onClick={() => setIsEditing(true)}
                  title="Edit About"
                />
              )}
            </h2>
          </div>
          {isEditing ? (
            <EditAbout
              initialAbout={userInfo.About}
              onSave={handleSaveAbout}
              onCancel={handleCancelEdit}
            />
          ) : (
            <p className="about-text">{userInfo.About}</p>
          )}
        </section>
        <section className="posts">
          <h2>Recent Reviews</h2>
          <ul>
            <li>
              <a href="#">Post Title 1</a>
            </li>
            <li>
              <a href="#">Post Title 2</a>
            </li>
            <li>
              <a href="#">Post Title 3</a>
            </li>
          </ul>
        </section>
      </div>
      <div className="logout-container">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default PrivateProfilePage;
