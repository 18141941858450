import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserInputContext,
  UpdateFunctionContext,
} from "../../../../context/ContextProvider";
import Modal from "../../../modal/Modal";

import { modelApiRequest, storeUserSkintypeInfoRequest } from "../../../../connection/ServerRequest";
import "./UserSkincareInputSubmitButton.css"; // Add the CSS file for loading UI


const UserSkincareInputSubmitButton = () => {
  const navigate = useNavigate();
  const userInputContext = useContext(UserInputContext);
  const { updateRecommendation } = useContext(UpdateFunctionContext);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkInputFilled = (context) => {
    for (const input in context) {
      if (context[input] === "") {
        return false;
      }
    }
    return true;
  };

  const handleClick = async () => {
    const inputFilled = checkInputFilled(userInputContext);
    if (inputFilled) {
      setIsLoading(true);
      const result = await modelApiRequest(userInputContext);
      setIsLoading(false);

      if (result.success) {
        const res = await storeUserSkintypeInfoRequest(
          userInputContext,
          result.data.combination_id
        );
        updateRecommendation(result.data.products);
        navigate("/userSkincareProductRecommendation");
      }
    } else {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Loading...</div>
        </div>
      )}
      <button
        className="submit-button"
        onClick={handleClick}
        disabled={isLoading} // Disable button during loading
      >
        Submit
      </button>
      {showModal && (
        <Modal message="All inputs must be filled" onClose={closeModal} />
      )}
    </Fragment>
  );
};

export default UserSkincareInputSubmitButton;
