import { Fragment } from "react";
import "./ForYouButton.css";
import { useNavigate } from "react-router-dom";

const ForYouButton = () => {

    const navigate = useNavigate();

    const handleForYouButtonClick = () => {
        navigate("/forYouRecommendation");
    }

    
    return (
        <Fragment>
            <div>
                <button onClick={handleForYouButtonClick}>For You</button>
            </div>
        </Fragment>
    )
}
export default ForYouButton;