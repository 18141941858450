import { Fragment } from "react";

const SearchProducts = () => {
    return (
        <Fragment>
            <div>
                Search Products
            </div>
        </Fragment>
    )
}
export default SearchProducts;