import { Fragment } from "react";
import { useNavigate } from "react-router-dom";


const Categories = () => {

    const navigate = useNavigate();

    const handleCategoriesButtonClick = (category) => {
        navigate(`/categories/${category}`);
    }

    return (
        <Fragment>
            <div className="categories">
                <button onClick={() => handleCategoriesButtonClick("makeup")}>Makeup</button>
                <button onClick={() => handleCategoriesButtonClick("skincare")}>Skincare</button>
                <button onClick={() => handleCategoriesButtonClick("hairFragrance")}>Hair Fragrance</button>
                <button onClick={() => handleCategoriesButtonClick("toolsAndBrushes")}>Tools & Brushes</button>
                <button onClick={() => handleCategoriesButtonClick("bathAndBody")}>Bath & Body</button>
            </div>
        </Fragment>
    )
}
export default Categories;