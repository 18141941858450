import { BrowserRouter } from "react-router-dom"; // Importing BrowserRouter for routing management
import ContextProvider from "./context/ContextProvider.jsx"; // Importing the ContextProvider to provide global state/context
import Routing from "./routing/Routing.jsx"; // Importing the Routing component that handles the application routes

/**
 * App component
 *
 * This is the root component of the application. It wraps the application in the
 * necessary providers and routing components to manage global state and navigation.
 *
 * @component
 * @returns {JSX.Element} The root component that sets up context and routing for the app.
 */
function App() {
  return (
    // Wrapping the app in the ContextProvider to make global state available
    <ContextProvider>
      {/* Wrapping the app in BrowserRouter to handle client-side routing */}
      <BrowserRouter basename="/">
        {/* Routing component that defines the application routes */}
        <Routing />
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App; // Exporting the App component as the default export
