import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import "./SocialMedia.css";

/**
 * Social Media component
 *
 * This component renders the "Social Media" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Social Media" section.
 */
const SocialMedia = () => {
  return (
    <Fragment>
      <button className="sns-button">
        <FontAwesomeIcon icon={faInstagram} /> Instagram
      </button>
      <button className="sns-button">
        <FontAwesomeIcon icon={faTiktok} /> TikTok
      </button>
    </Fragment>
  );
};

export default SocialMedia;
