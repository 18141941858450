import { Fragment, useState } from "react";
import "./CategoriesButton.css";
import { useNavigate } from "react-router-dom";

const CategoriesButton = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();

    const handleCategoriesButtonClick = () => {
        navigate("/categories");
    };

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <Fragment>
            <div 
                className="categories-button-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <button onClick={handleCategoriesButtonClick}>Categories</button>
                {isDropdownVisible && (
                    <div className="dropdown-menu">
                        <div onClick={() => navigate("/categories/makeup")}>Makeup</div>
                        <div onClick={() => navigate("/categories/skincare")}>Skincare</div>
                        <div onClick={() => navigate("/categories/hairFragrance")}>Hair Fragrance</div>
                        <div onClick={() => navigate("/categories/toolsAndBrushes")}>Tools & Brushes</div>
                        <div onClick={() => navigate("/categories/bathAndBody")}>Bath & Body</div>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default CategoriesButton;
