import { Fragment } from "react";

const BathAndBody = () => {
    return (
        <Fragment>
            <div>
                <h1>Bath and Body</h1>
            </div>
        </Fragment>

    )
}
export default BathAndBody