import { Fragment } from "react";

const Makeup = () => {
    return (
        <Fragment>
            <div>
                <h1>Makeup</h1>
            </div>
        </Fragment>
    );
}
export default Makeup; 