import { Link } from "react-router-dom";
import { Fragment } from "react";
import "./Logo.css";

/**
 * Logo component
 *
 * This component renders the "Logo" section.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Logo" section.
 */
const Logo = () => {
  return (
    <Fragment>
      <Link to="/">
        <img src="dasodaLogo.png" alt="dasoda logo" class="dasodaLogo" />
      </Link>
    </Fragment>
  );
};
export default Logo;
