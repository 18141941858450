import { Fragment, useContext, useEffect, useState } from "react";
import { RecommendationContext } from "../../../context/ContextProvider";
import ProductList from "../../productList/ProductList";
import useRedirectOnRefresh from "../../../hook/useRedirectOnRefresh";
import Pagination from "react-js-pagination";
import BackButton from "../../backButton/BackButton";

/**
 * UserSkincareProductRecommendation component
 *
 * This component renders the user's skincare product recommendations after the submit button is clicked.
 * It displays the user's skincare attributes filled in the previous form.
 *
 * @component
 * @returns {JSX.Element} A React component that displays the user's skincare product recommendations.
 */
const UserSkincareProductRecommendation = () => {
  // Destructure the context to get user input values
  const recommendationContext = useContext(RecommendationContext);
  useRedirectOnRefresh("/");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = recommendationContext.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Fragment>
      <div>
        <BackButton />
        <h2>These are the items that fits the best for you!</h2>
        <ProductList products={currentItems} />
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={recommendationContext.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    </Fragment>
  );
};
export default UserSkincareProductRecommendation;
