// data that should be displayed on dropdown menu
export const DropDownData = {
  skinTone: [
    "",
    "fairLight",
    "fair",
    "light",
    "lightMedium",
    "medium",
    "mediumTan",
    "tan",
    "deep",
    "rich",
    "deep",
    "notSureT"
  ],
  // eyeColor: ["", "brown", "hazel", "blue", "green", "gray", "Grey"],
  skinType: ["", "dry", "combination", "normal", "oily"],
  // hairColor: [
  //   "",
  //   "black",
  //   "blonde",
  //   "brown",
  //   "brunette",
  //   "gray",
  //   "auburn",
  //   "red",
  // ],
  pore: ["", "Yes", "No"],
  sensitive: ["", "Yes", "No"],
  blemish: ["", "Yes", "No"],
  aging: ["", "Yes", "No"],
  uneven: ["", "Yes", "No"],
  wrinkle: ["", "Yes", "No"],
  darkCircle:["", "Yes", "No"],
  acnePimple: ["", "Yes", "No"],
};
