import { Fragment } from "react"
import "./ForYouRecommendation.css"
import { useState, useContext, useEffect } from "react"
import UserSkincareProductRecommendation from "../userSkincareProductRecommendation/UserSkincareProductRecommendation";
import UserSkincareInput from "../userSkincareInput/UserSkincareInput";
import { RecommendationContext } from "../../../context/ContextProvider";

const ForYouRecommendation = () => {
    const [userInputExists, setUserInputExists] = useState(false);
    
    const recommendationContext = useContext(RecommendationContext);

    useEffect(() => {
        if (recommendationContext.length > 0) {
            setUserInputExists(true);
        } else {
            setUserInputExists(false);
        }
    }, [recommendationContext])

    return (
        <Fragment>
            { userInputExists && (
                <Fragment>
                    <UserSkincareProductRecommendation />
                </Fragment>
            )}
            { !userInputExists && (
                <Fragment>
                    <UserSkincareInput />
                </Fragment>
            )}
        </Fragment>
    )
}
export default ForYouRecommendation;