import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook that redirects to a specified page if the current page was refreshed.
 *
 * @param {string} redirectTo - The path to redirect to if the page was refreshed.
 */
const useRedirectOnRefresh = (redirectTo) => {
  const navigate = useNavigate();

  useEffect(() => {
    const wasRefreshed = sessionStorage.getItem("wasRefreshed");

    if (wasRefreshed) {
      navigate(redirectTo);
      sessionStorage.removeItem("wasRefreshed");
    }
  }, [navigate, redirectTo]);

  useEffect(() => {
    sessionStorage.setItem("wasRefreshed", "true");

    return () => {
      sessionStorage.removeItem("wasRefreshed");
    };
  }, []);
};

export default useRedirectOnRefresh;
