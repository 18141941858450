import { Fragment, useState, useContext, useEffect } from "react"; // Importing necessary hooks and Fragment from React
import {
  UserInputContext,
  UpdateFunctionContext,
} from "../../../../context/ContextProvider"; // Importing context objects to access global state and update functions
import "./UserSkincareInputDropDown.css"; // Importing the CSS file for styling the dropdown component

/**
 * UserSkincareInputDropDown component
 *
 * This component renders a dropdown menu that allows users to select a value related to their skincare input.
 * It interacts with global state via context to store and retrieve the selected value.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string[]} props.data - The array of dropdown options
 * @param {string} props.inputField - The field name in the user input context that this dropdown modifies
 * @returns {JSX.Element} A React component that displays a dropdown menu for skincare input.
 */
const UserSkincareInputDropDown = ({ data, inputField, onSelectionChange }) => {
  const userInputContext = useContext(UserInputContext); // Accessing the user input context to retrieve stored values
  const { updateUserInput } = useContext(UpdateFunctionContext); // Accessing the update function context to update user input values

  const persistedValue = userInputContext[inputField]; // Retrieving the persisted value for this specific input field from the context
  const [selectedValue, setSelectedValue] = useState(persistedValue); // State for managing the currently selected value in the dropdown

  // Effect to update the selected value when the persisted value changes
  useEffect(() => {
    if (persistedValue) {
      setSelectedValue(persistedValue); // Sync the selected value with the persisted value
    }
  }, [persistedValue]); // The effect depends on the persistedValue

  /**
   * handleChange function
   *
   * This function handles the change event of the dropdown menu. It updates both the component state
   * and the global user input context with the selected value.
   *
   * @param {Object} event - The change event triggered by selecting a dropdown option
   */
  const handleChange = (event) => {
    const value = event.target.value; // Extracting the selected value from the event
    setSelectedValue(value); // Updating the local state with the selected value
    updateUserInput(inputField, value); // Updating the global context with the new value for the input field
    onSelectionChange();
  };

  return (
    <Fragment>
      {/* Dropdown container with applied styling */}
      <div className="dropdown-span">
        <select
          value={selectedValue}
          className="select"
          onChange={handleChange}
        >
          {/* Mapping over the data array to create an option element for each entry */}
          {data.map((entry, index) => (
            <option key={index} value={entry}>
              {entry}
            </option>
          ))}
        </select>
      </div>
    </Fragment>
  );
};

export default UserSkincareInputDropDown; // Exporting the UserSkincareInputDropDown component as the default export
