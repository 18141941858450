import React, { useState } from "react"; // Importing React and useState hook for managing component state
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for programmatic navigation
import "./SignUpPage.css"; // Importing the CSS file for styling the SignUp component
import { signUpRequest } from "../../../connection/ServerRequest.js"; // Importing the SignUpRequest function for handling sign-up requests to the server

/**
 * SignUp component
 *
 * This component handles user registration. It provides a form for users to input their
 * registration details and submit them to create a new account.
 *
 * @component
 * @returns {JSX.Element} The JSX for the SignUp component.
 */
const SignUp = () => {
  const navigate = useNavigate(); // Hook for navigating programmatically to different routes

  // State for managing the sign-up form data
  const [formData, setFormData] = useState({
    UserID: "", // User ID field
    Username: "", // Username field
    Email: "", // Email field
    Password: "", // Password field
    Gender: "", // Gender field
    BirthDate: "", // Birthdate field
    PhoneNumber: "", // Phone number field
  });

  // Handler function to update formData state when the user types in any input field
  const handleChange = (event) => {
    const { name, value } = event.target; // Destructuring the name and value from the event target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamically updating the formData state based on the input field's name
    }));
  };

  // Handler function for form submission, sends a sign-up request to the server
  const handleSignUp = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    const response = await signUpRequest(formData); // Sends the sign-up request with the form data

    if (response.success) {
      navigate("/signIn"); // Navigates to the sign-in page on successful sign-up
    }
  };

  // Handler function for the login link click, navigates to the login page
  const handleLoginClick = (event) => {
    event.preventDefault(); // Prevents the default link behavior
    navigate("/signIn"); // Navigates to the sign-in page
  };

  return (
    <div className="signup-form-box">
      <form className="signup-form" onSubmit={handleSignUp}>
        <span className="signup-title">Sign up</span>
        <span className="signup-subtitle">
          Create a free account with your email.
        </span>
        <div className="signup-form-container">
          {/* Input fields for the sign-up form, bound to the formData state */}
          <input
            type="text"
            className="signup-input"
            placeholder="ID"
            name="UserID"
            value={formData.UserID}
            onChange={handleChange} // Updates UserID in formData state
            required
          />
          <input
            type="text"
            className="signup-input"
            placeholder="Full Name"
            name="Username"
            value={formData.Username}
            onChange={handleChange} // Updates Username in formData state
            required
          />
          <input
            type="email"
            className="signup-input"
            placeholder="Email"
            name="Email"
            value={formData.Email}
            onChange={handleChange} // Updates Email in formData state
            required
          />
          <input
            type="password"
            className="signup-input"
            placeholder="Password"
            name="Password"
            value={formData.Password}
            onChange={handleChange} // Updates Password in formData state
            required
          />
          <select
            className="signup-input"
            name="Gender"
            value={formData.Gender}
            onChange={handleChange} // Updates Gender in formData state
            required
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <input
            type="date"
            className="signup-input"
            placeholder="Birthdate"
            name="BirthDate"
            value={formData.BirthDate}
            onChange={handleChange} // Updates BirthDate in formData state
            required
          />
          <input
            type="tel"
            className="signup-input"
            placeholder="Phone Number"
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleChange} // Updates PhoneNumber in formData state
            pattern="[+]?[0-9]{7,15}" // Regex pattern for phone number validation
            title="Phone number should be between 7 to 15 digits, with an optional leading +"
          />
        </div>
        <button type="submit" className="signup-submit-button">
          Sign up
        </button>
      </form>
      <div className="signup-form-section">
        <p>
          Have an account?{" "}
          <a href="/signIn" onClick={handleLoginClick}>
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUp; // Exporting the SignUp component as the default export
