import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for programmatic navigation
import { Fragment } from "react"; // Importing Fragment from React to group multiple elements

/**
 * BackButton component
 *
 * This component renders a button that navigates back to the previous page when clicked.
 * It leverages the useNavigate hook from react-router-dom to handle the navigation.
 *
 * @component
 * @returns {JSX.Element} A React component that displays a "Back" button.
 */
const BackButton = () => {
  const navigate = useNavigate(); // Hook for navigating programmatically to different routes

  /**
   * Handles the click event to navigate back to the previous page.
   *
   * @function handleBack
   */
  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page in the browser's history
  };

  return (
    <Fragment>
      <button onClick={handleBack} className="submit-button">
        &lt;
      </button>
      {/* Renders a button that triggers handleBack when clicked */}
    </Fragment>
  );
};

export default BackButton; // Exporting the BackButton component as the default export
